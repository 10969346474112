<template>
  <v-app>
    <v-main>
      <v-container fluid class="fill-height">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <div class="text-center mb-5 font-weight-light display-1">
              <span class="secondary--text">Vending</span>
              <span class="red--text"> Admin</span>
            </div>
            <v-card>
              <v-card-text>
                <v-form v-model="isValid">
                  <v-text-field
                    label="Логин"
                    outlined
                    autofocus
                    v-model="username"
                  ></v-text-field>
                  <v-text-field
                    label="Пароль"
                    outlined
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    @keyup.enter="login"
                  ></v-text-field>
                  <v-btn
                    block
                    x-large
                    depressed
                    color="primary"
                    class="text-capitalize font-weight-regular"
                    :class="{ 'disable-events': !username && !password }"
                    @click="login"
                    >Войти</v-btn
                  >
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <Snackbar />
    <Progress />
  </v-app>
</template>

<script>
import axios from "axios";
import Snackbar from "./Snackbar/Snackbar.vue";
import Progress from "./Progress/Progress.vue";
import { parseJwt } from "./../utils";

export default {
  components: { Snackbar, Progress },

  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
      isValid: false,
    };
  },

  methods: {
    login() {
      const credentials = {
        userName: this.username,
        password: this.password,
      };
      axios
        .post(`${process.env.VUE_APP_AUTH_BASE_URL}/auth/login`, credentials)
        .then((r) => {
          const token = r.data.token;
          const role = parseJwt(token).role;
          if (role === "Admin") {
            localStorage.setItem("token", token);
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            this.$router.push({ path: "/app" });
          } else {
            this.$store.commit("snackbar/SHOW", "Нет прав");
          }
        })
        .catch((e) => {
          const msg = e.response.data.message || e.response.data.Message;
          this.$store.commit("snackbar/SHOW", msg);
        });
    },
  },
};
</script>